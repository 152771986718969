import { gql } from '@apollo/client';

export const GET_CUSTOMERS_LIST = gql`
  query getAllCustomers(
    $status: Float!
    $page: Float!
    $limit: Float!
    $roleId: Float!
    $searchText: String
    $sortingColumn: String
    $sortingDirection: String
  ) {
    getAllCustomers(
      status: $status
      page: $page
      limit: $limit
      roleId: $roleId
      searchText: $searchText
      sortingColumn: $sortingColumn
      sortingDirection: $sortingDirection
    ) {
      success
      totalRecords
      data {
        id
        status
        firstName
        lastName
        fullName
        email
        address
        address2
        city
        state
        zip
        phone
        mobileNumber
        numberCode
        countryCode
        stateCode
        createdAt
      }
    }
  }
`;

export const ACTIVATE_DEACTIVATE_CUSTOMER = gql`
  mutation activateDeactivateUser($userId: String!, $isActive: Boolean!) {
    activateDeactivateUser(userId: $userId, isActive: $isActive) {
      success
      message
    }
  }
`;

export const GET_CUSTOMER_DETAIL = gql`
  query customerHistory($userId: String!) {
    customerHistory(userId: $userId)
  }
`;

export const UPDATE_CUSTOMER_EMAIL = gql`
  mutation updateUserEmail($customerEmailArgs: UpdateCustomerEmailArgs!) {
    updateUserEmail(customerEmailArgs: $customerEmailArgs)
  }
`;
