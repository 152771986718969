import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Box, MenuItem, TableCell, TableRow } from '@mui/material';
import {
  ContentCopyOutlined as ContentCopyOutlinedIcon,
  EngineeringOutlined as EngineeringOutlinedIcon,
  ClearOutlined as ClearOutlinedIcon,
  WbSunny as WbSunnyIcon,
  Nightlight as NightlightIcon,
  VisibilityOutlined as VisibilityOutlinedIcon,
  TextSnippetOutlined as TextSnippetOutlinedIcon,
  LocalPrintshopOutlined as LocalPrintshopOutlinedIcon,
  Edit as EditIcon,
  DeleteOutline as DeleteOutlinedIcon,
  CheckCircle as CheckCircleIcon,
} from '@mui/icons-material';

import CustomThreeDotsMenu from '../customMenu/CustomThreeDotsMenu';
import {
  capitalizeFirstLetter,
  downloadPDF,
  isSafari,
  setSessionStorage,
} from '../../utils/utilities';
import { formatDate } from '../../utils/formatDate';
import { ISnackBar } from '../../models/common';
import { Messages, reservationStatus, Severity, paidStatus } from '../../enum/enumList';
import { CustomTooltip } from '../CustomTooltip';
import { formatReservationId } from '../../utils/formatReservationId';
import { LazyQueryExecFunction, OperationVariables } from '@apollo/client';

export interface IReservationRow {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  address: string;
  address2: string;
  city: string;
  zip: string;
  state: string;
  reservationType?: any;
  startDate?: any;
  endDate?: any;
  subTotal: number;
  tax: number;
  totalAmount: number;
  isCompleted: number;
  isReturned: string;
  isPickupDayBefore: number;
  isWaiverSigned: number;
  createdAt: Date;
  updatedAt: Date;
  storeId: number;
  createdBy: string;
  userId: string;
  countryId: number;
  stateId: number;
  adminDiscount: number;
  discount: number;
  isFixedDiscount: number;
  fullName: string;
  pickupDate?: any;
  waiverSigned: string;
  renters: string;
  isBadge: boolean;
  returnDate: string;
  noOfRenters: string;
  isEquipmentAssigned: boolean;
  status: string;
  equipmentAssigned: string;
  totalAmountPaid: number;
  amountDue: number;
  paymentStatus: paidStatus;
}

interface IReservationListItem {
  reservationRow: IReservationRow;
  deleteConfirmation: (param: string | number) => void;
  snackbarShowMessage?: ISnackBar;
  handleCancelReservationClick: (reservationId: number, storeId: number) => void;
  openDuplicateReservation: (reservationId: string | number) => void;
  printPreviewReservation: LazyQueryExecFunction<any, OperationVariables>;
}

const ReservationListItem = ({
  reservationRow,
  deleteConfirmation,
  snackbarShowMessage,
  handleCancelReservationClick,
  openDuplicateReservation,
  printPreviewReservation,
}: IReservationListItem) => {
  const navigate = useNavigate();

  const openPDF = (data: string) => {
    // generate pdf in new window using pdf base64 string

    if (isSafari) {
      return downloadPDF(data, 'reservation_details');
    }
    const win = window.open('WaiverPDF', '_blank');
    if (win)
      win.document.write(
        '<title>Reservation Details PDF</title>' +
          '<body style="overflow: hidden; margin: 0">' +
          '<object width="100%" width="-webkit-fill-available" height="100%" height="-webkit-fill-available" type="application/pdf" data="' +
          encodeURI(data) +
          '"></object>' +
          '</body>',
      );
  };

  const viewHistoryClick = () => {
    navigate(`/customer-detail/${reservationRow?.userId || ''}`);
  };

  const reservationDetailClick = () => {
    setSessionStorage('reservationId', reservationRow?.id ?? null);
    navigate('/reservation/reservation-detail');
  };

  const completeReservation = () => {
    setSessionStorage('reservationId', reservationRow?.id ?? null);
    navigate('/reservation/contact-info');
  };

  const techStationClick = () => {
    // check if  try to aceess tech station page before reservation is started
    if (
      moment(reservationRow?.startDate).isAfter(new Date()) &&
      (reservationRow?.isPickupDayBefore
        ? moment(reservationRow?.startDate).subtract(1, 'days').isAfter(new Date())
        : true)
    ) {
      if (snackbarShowMessage)
        return snackbarShowMessage(
          'Cannot assign equipment before reservation start date',
          Severity.Error,
        );
    }

    // check is waiver is signed or not
    if (!reservationRow?.isWaiverSigned) {
      if (snackbarShowMessage)
        return snackbarShowMessage(Messages.assignEquipmentError, Severity.Error);
    }

    setSessionStorage('reservationId', reservationRow?.id ?? null);
    navigate('/reservation/tech-listing');
  };

  const onDeleteReservation = () => {
    deleteConfirmation(reservationRow?.id);
  };

  const handlePrintPreviewReservation = () => {
    printPreviewReservation({
      variables: { reservationId: reservationRow?.id, storeId: reservationRow?.storeId },
      onCompleted(data) {
        if (data?.printPreview?.data) return openPDF(data?.printPreview?.data);
        if (snackbarShowMessage) snackbarShowMessage('Something went wrong', Severity.Error);
      },
      onError(error) {
        if (snackbarShowMessage) return snackbarShowMessage(error.message, Severity.Error);
      },
    });
  };

  const handleNameClick = () => {
    if (
      reservationRow.status === reservationStatus.cancelled ||
      reservationRow.status === reservationStatus.completed
    )
      return reservationDetailClick();

    if (reservationRow.status === reservationStatus.pending) return completeReservation();

    return techStationClick();
  };

  return (
    <>
      <TableRow
        hover
        tabIndex={-1}
        key={reservationRow.fullName}
        className={
          reservationRow?.status === reservationStatus.pending
            ? 'reservation-pending'
            : reservationRow.status === reservationStatus.cancelled
            ? 'reservation-cancelled'
            : reservationRow.status === reservationStatus.completed
            ? 'reservation-completed'
            : ''
        }
      >
        <TableCell id={reservationRow?.id?.toString()} scope='row'>
          <Box className='cell-wrap'>
            {!reservationRow?.isPickupDayBefore ? (
              <WbSunnyIcon className='cell-wrap__sun' />
            ) : (
              <NightlightIcon className='cell-wrap__moon' />
            )}
            <Box component='a' onClick={handleNameClick} className='cell-wrap__text-ellipse'>
              {reservationRow?.fullName}
            </Box>
          </Box>
        </TableCell>
        <TableCell>
          <Box component='span' className='cell-info'>
            {reservationRow?.status === reservationStatus.pending ? (
              formatReservationId(reservationRow?.id, reservationRow?.storeId)
            ) : (
              <Box className='cell-wrap'>
                <Box
                  component='a'
                  className='cell-wrap__text-ellipse'
                  onClick={reservationDetailClick}
                >
                  {formatReservationId(reservationRow?.id, reservationRow?.storeId)}
                </Box>
              </Box>
            )}
            {reservationRow?.waiverSigned === 'Yes' && (
              <Box component='span' className='cell-info__waiver-icon'>
                <CustomTooltip title='Waiver Signed' placement='right' arrow>
                  <CheckCircleIcon fontSize='small' />
                </CustomTooltip>
              </Box>
            )}
          </Box>
        </TableCell>
        <TableCell>
          <Box
            component='span'
            className={
              reservationRow?.waiverSigned === 'Yes' ? 'badge badge--green' : 'badge badge--red'
            }
          >
            {reservationRow?.waiverSigned}
          </Box>
        </TableCell>
        <TableCell>
          {reservationRow?.pickupDate ? formatDate({ date: reservationRow?.pickupDate }) : 'N/A'}
        </TableCell>
        <TableCell>{formatDate({ date: reservationRow?.endDate }) || 'N/A'}</TableCell>
        <TableCell>{reservationRow?.renters || 'N/A'}</TableCell>
        <TableCell>
          <Box
            component='span'
            className={`badge text-capitalize ${
              reservationRow?.isReturned === 'no'
                ? 'badge badge--red'
                : reservationRow?.isReturned === 'yes'
                ? 'badge badge--green'
                : 'badge badge--blue'
            }`}
          >
            {reservationRow?.isReturned}
          </Box>
        </TableCell>
        <TableCell>
          <Box
            component='span'
            className={
              reservationRow?.equipmentAssigned === 'no'
                ? 'badge badge--red'
                : reservationRow?.equipmentAssigned === 'yes'
                ? 'badge badge--green'
                : 'badge badge--blue'
            }
          >
            {capitalizeFirstLetter(reservationRow?.equipmentAssigned)}
          </Box>
        </TableCell>
        {/* Added Paid Column */}
        {/* if no amount is due, paid status will be yes, if some amount is paid and some amount is due paid status will be partially
            and id no amount is paid and total amount is due, paid status will be no */}
        <TableCell>
          {reservationRow?.paymentStatus == paidStatus?.yes ? (
            <Box component='span' className='badge badge--green'>
              {paidStatus?.yes}
            </Box>
          ) : reservationRow?.paymentStatus == paidStatus?.partial ? (
            <Box component='span' className='badge badge--blue'>
              {paidStatus?.partial}
            </Box>
          ) : reservationRow?.status == reservationStatus.pending ||
            reservationRow?.paymentStatus == paidStatus?.no ? (
            <Box component='span' className='badge badge--red'>
              {paidStatus?.no}
            </Box>
          ) : null}
        </TableCell>
        <TableCell align='right'>
          <CustomThreeDotsMenu labelId={reservationRow?.id?.toString()}>
            {reservationRow?.status !== reservationStatus.pending ? (
              <>
                <MenuItem onClick={() => openDuplicateReservation(reservationRow?.id || '')}>
                  <ContentCopyOutlinedIcon /> Duplicate
                </MenuItem>
                <MenuItem onClick={() => viewHistoryClick()}>
                  <VisibilityOutlinedIcon /> Customer History
                </MenuItem>
                <MenuItem onClick={handlePrintPreviewReservation}>
                  <LocalPrintshopOutlinedIcon /> Print Preview
                </MenuItem>
                <MenuItem onClick={() => reservationDetailClick()}>
                  <TextSnippetOutlinedIcon /> Reservation Detail
                </MenuItem>
                {reservationRow?.status === reservationStatus.processing && (
                  <MenuItem onClick={() => techStationClick()}>
                    <EngineeringOutlinedIcon /> Tech Station
                  </MenuItem>
                )}
                {reservationRow?.status === reservationStatus.processing && (
                  <MenuItem
                    onClick={() =>
                      handleCancelReservationClick(reservationRow?.id, reservationRow?.storeId)
                    }
                  >
                    <ClearOutlinedIcon /> Cancel Reservation
                  </MenuItem>
                )}
              </>
            ) : (
              <>
                <MenuItem onClick={completeReservation}>
                  <EditIcon /> Complete Reservation
                </MenuItem>
                <MenuItem onClick={onDeleteReservation}>
                  <DeleteOutlinedIcon /> Delete
                </MenuItem>
              </>
            )}
          </CustomThreeDotsMenu>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ReservationListItem;
