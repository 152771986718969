import React, {
  createContext,
  useEffect,
  useMemo,
  useState,
  ReactNode,
  SyntheticEvent,
} from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { ACTIVATE_DEACTIVATE_CUSTOMER, GET_CUSTOMERS_LIST } from '../../graphql/api/customers';
import {
  columnSortingOrder,
  Constants,
  preferableAudience,
  roles,
  Severity,
} from '../../enum/enumList';
import { CustomSnackbar } from '../../hoc/CustomSnackbar';
import { ISnackBar } from '../../models/common';
import { useAuth } from '../../hooks/useAuth';
import { CustomerTabelCell } from './tableConfig';
import { formatfilters, getDefaultSortableColumn } from '../../utils/utilities';
import useSessionStorage from '../../hooks/useSessionStorage';

interface CustomerProvider {
  children: ReactNode;
  snackbarShowMessage?: ISnackBar;
}

export const CustomerContext = createContext<any>({});

const CustomerProvider = ({ children, snackbarShowMessage }: CustomerProvider) => {
  const initalFilter = {
    customerFilter: formatfilters(preferableAudience.customer)[0].value,
    searchText: '',
  };
  const { user } = useAuth();
  const [tabValue, setTabValue] = useState(roles.user);
  const [filter, setFilter, resetFilter] = useSessionStorage('customerFilters', {
    customerFilter: 2,
    searchText: '',
  });

  const alteredTabelCell = useMemo(() => {
    return CustomerTabelCell.filter((item) => {
      if (tabValue === roles.guest) {
        return item?.id !== 'status';
      }
      return item;
    });
  }, [tabValue]);

  const handleChange = (_event: SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    setFilter((prevState: any) => ({ ...prevState, customerFilter: 2 }));
  };

  const [getCustomerList, { data = {}, loading, refetch }] = useLazyQuery(GET_CUSTOMERS_LIST, {
    fetchPolicy: 'network-only',
  });

  const [changeStatus, { loading: statusFetching }] = useMutation(ACTIVATE_DEACTIVATE_CUSTOMER, {
    onCompleted: (data) => {
      const { activateDeactivateUser: { message = '' } = {} } = data || {};
      if (snackbarShowMessage) snackbarShowMessage(message, Severity.Success);
      refetch();
    },
  });

  const activeInactiveCustomer = (customerId: string, status: number): Promise<any> => {
    return changeStatus({
      variables: {
        userId: customerId,
        isActive: status === 1 ? true : false,
      },
    });
  };

  const { getAllCustomers = {} } = data;
  const { data: customersList = [], totalRecords = 0 } = getAllCustomers;

  useEffect(() => {
    getCustomerList({
      variables: {
        page: 0,
        limit: Constants.rowsPerPage,
        roleId: tabValue,
        searchText: filter.searchText || null,
        sortingColumn: getDefaultSortableColumn(alteredTabelCell, 'createdAt'),
        sortingDirection: columnSortingOrder,
        status: filter.customerFilter,
      },
    });
  }, [tabValue, filter.customerFilter, user?.selectedStore]);

  const handleTableInteraction = (
    page = 0,
    limit = Constants.rowsPerPage,
    sortingDirection = columnSortingOrder,
    sortingColumn = 'createdAt',
    searchText = '',
  ) => {
    getCustomerList({
      variables: {
        page,
        limit,
        roleId: tabValue,
        searchText: searchText || filter.searchText,
        sortingColumn,
        sortingDirection,
        status: filter.customerFilter,
      },
    });
  };

  const resetFilters = () => {
    resetFilter();
    if (
      filter.customerFilter == initalFilter.customerFilter &&
      filter.searchText != initalFilter.searchText
    ) {
      handleTableInteraction(0, Constants.rowsPerPage, 'DESC', 'createdAt', ' ');
    }
  };

  const value = useMemo(
    () => ({
      tabValue,
      setTabValue,
      handleChange,
      customersList,
      handleTableInteraction,
      totalRecords,
      searchText: filter.searchText,
      loading,
      filter,
      setFilter,
      activeInactiveCustomer,
      statusFetching,
      alteredTabelCell,
      resetFilters,
      snackbarShowMessage,
      refetch,
    }),
    [tabValue, customersList, totalRecords, loading, filter, statusFetching],
  );

  return <CustomerContext.Provider value={value}>{children}</CustomerContext.Provider>;
};

export default CustomSnackbar(CustomerProvider);
