import { useState } from 'react';

function useSessionStorage(key: string, initialValue: any) {
  // Retrieve the value from sessionStorage or use the initial value
  const readValueFromSessionStorage = () => {
    try {
      const storedValue = sessionStorage.getItem(key);
      // If a value exists in sessionStorage, parse it. Otherwise, use the initialValue.
      return storedValue ? JSON.parse(storedValue) : initialValue;
    } catch (error) {
      console.error(`Error reading sessionStorage key "${key}":`, error);
      return initialValue;
    }
  };

  // State to store the current value
  const [storedValue, setStoredValue] = useState(readValueFromSessionStorage);

  // Function to update both sessionStorage and the state
  const setValue = (value: any) => {
    try {
      // If the value is a function, invoke it to get the actual value (similar to useState)
      const valueToStore = value instanceof Function ? value(storedValue) : value;

      // Save the value in state
      setStoredValue(valueToStore);

      // Save the value to sessionStorage as a string
      sessionStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.error(`Error setting sessionStorage key "${key}":`, error);
    }
  };

  const reset = () => {
    setValue(initialValue);
    sessionStorage.removeItem(key);
  };

  return [storedValue, setValue, reset];
}

export default useSessionStorage;
