import moment from 'moment';
import { HeadCell, IStores } from '../models/common';
import { SeasonLabel, preferableAudience } from '../enum/enumList';

type argType = { [key: string]: string };

export const dateTimeExtractor = (date: Date | string | number): string => {
  const resultedDate = new Date(date);
  const extractedDate =
    resultedDate.getFullYear() +
    '-' +
    (resultedDate.getMonth() >= 9
      ? resultedDate.getMonth() + 1
      : '0' + (resultedDate.getMonth() + 1)) +
    '-' +
    (resultedDate.getDate() >= 10 ? resultedDate.getDate() : '0' + resultedDate.getDate());
  const extractedTime =
    resultedDate.getHours() + ':' + resultedDate.getMinutes() + ':' + resultedDate.getSeconds();
  return `${extractedDate}`;
};

export const getFormatedDateTime = (date: Date | string | number) => {
  return (
    // new Date(date).toLocaleString('en-us', {
    //   month: 'short',
    //   year: 'numeric',
    //   day: 'numeric',
    // }) +
    // ' ' +
    // new Date(date).toLocaleTimeString('en-us', { hour: '2-digit', minute: '2-digit' })
    moment(date).utc().format('YYYY-MM-DD hh:mm a')
  );
};

export const parse = (text: string, matches: any) => {
  const result = [];

  if (matches.length === 0) {
    result.push({
      text,
      highlight: false,
    });
  } else if (matches[0][0] > 0) {
    result.push({
      text: text.slice(0, matches[0][0]),
      highlight: false,
    });
  }

  matches.forEach((match: any, i: number) => {
    const startIndex = match[0];
    const endIndex = match[1];

    result.push({
      text: text.slice(startIndex, endIndex),
      highlight: true,
    });

    if (i === matches.length - 1) {
      if (endIndex < text.length) {
        result.push({
          text: text.slice(endIndex, text.length),
          highlight: false,
        });
      }
    } else if (endIndex < matches[i + 1][0]) {
      result.push({
        text: text.slice(endIndex, matches[i + 1][0]),
        highlight: false,
      });
    }
  });

  return result;
};

export const formatCardNumber = (value: string) => {
  const v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
  const matches = v.match(/\d{4,16}/g);
  const match = (matches && matches[0]) || '';
  const parts = [];

  // To check whether the card is American Express as American Express Cards starts with '34' & '37'
  if (matches?.[0].startsWith('34') || matches?.[0].startsWith('37')) {
    return matches?.[0]
      .replace(/\s/g, '')
      .replace(/(\d{4})\s*(\d{6})?\s*(\d{5})?/, '$1 $2 $3')
      .replace(/\s\s/g, ' ')
      .trim();
  }
  for (let i = 0; i < match.length; i += 4) {
    parts.push(match.substring(i, i + 4));
  }

  if (parts.length) {
    return parts.join(' ');
  } else {
    return value;
  }
};

export const setSessionStorage = (key: string, value: unknown) => {
  if (typeof value === 'object') {
    sessionStorage.setItem(key, JSON.stringify(value));
  } else {
    sessionStorage.setItem(key, value as string);
  }
};

export const removeSessionStorage = (key: string) => {
  sessionStorage.removeItem(key);
};

export function capitalizeFirstLetter(data: string) {
  return data?.charAt(0)?.toUpperCase() + data?.slice(1);
}

export const formatfilters = (type: preferableAudience, activeValue?: number) => {
  let filters = [
    {
      label: 'All',
      value: 2,
    },
    {
      label: `Active ${type}`,
      value: activeValue,
    },
    {
      label: `Inactive ${type}`,
      value: 0,
    },
    {
      label: 'Admin',
      value: 3,
    },
  ];

  filters = filters.filter((item) => {
    if (type === preferableAudience.customer) {
      return item.label !== 'Admin';
    }
    return item;
  });

  return filters;
};

export const renderStoreName = (stores: IStores[], selectedStore: number | string) => {
  return stores?.filter((item) => item.id === selectedStore)[0].name;
};

export const testforNumbers = () => {};

export const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export function downloadPDF(base64Data: string, fileName: string) {
  // Convert Base64 to Blob
  const encodedData = base64Data.split(',')[1];
  const byteCharacters = atob(encodedData);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: 'application/pdf' });

  // Create download link
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = fileName;

  // Trigger the download
  document.body.appendChild(link);
  link.click();

  // Clean up
  document.body.removeChild(link);
  URL.revokeObjectURL(link.href);
}

export function getDefaultSortableColumn(headers: HeadCell[], keyword?: string) {
  if (keyword) {
    return (
      (headers &&
        headers
          .filter((item) => item.id.toLowerCase().includes(keyword.toLowerCase()))
          .map((item) => item.id)[0]) ||
      ''
    );
  }
  return (headers && headers[0]?.id) || '';
}

export function renderSelectedSeason(condition: boolean) {
  return condition
    ? capitalizeFirstLetter(SeasonLabel.summer)
    : capitalizeFirstLetter(SeasonLabel.winter);
}

export function deepEqual<T>(obj1: T, obj2: T) {
  if (obj1 === obj2) return true;

  if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
    return false;
  }

  const keys1 = Object.keys(obj1) as Array<keyof T>;
  const keys2 = Object.keys(obj2) as Array<keyof T>;

  if (keys1.length !== keys2.length) return false;

  for (const key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key] || '', obj2[key] || '')) {
      return false;
    }
  }

  return true;
}
