import * as Yup from 'yup';

import { EmailRegex, Messages } from '../../../../enum/enumList';

export const UpdateCustomerEmailValidator = Yup.object().shape({
  email: Yup.string()
    .required(Messages.required)
    .trim()
    .matches(EmailRegex, Messages.emailErr)
    .test('email-match', 'Entered email is identical to existing email', function (value) {
      // Access the context value via `this.options.context`
      const contextEmail = this.options.context?.email;
      return !(value == contextEmail);
    })
    .typeError(Messages.required),
});
