import { gql } from '@apollo/client';
// reservation type added in the query
export const GET_RESERVATION_LIST = gql`
  query getReservationList(
    $storeId: Float!
    $page: Float!
    $limit: Float!
    $searchText: String
    $equipmentAssigned: String
    $dateFilter: String
    $sortingColumn: String
    $sortingDirection: String
    $dateRangeFilter: String
    $reservationType: String
    $isAdmin: Boolean
  ) {
    getAllReservations(
      storeId: $storeId
      page: $page
      limit: $limit
      searchText: $searchText
      equipmentAssigned: $equipmentAssigned
      dateFilter: $dateFilter
      sortingColumn: $sortingColumn
      sortingDirection: $sortingDirection
      dateRangeFilter: $dateRangeFilter
      reservationType: $reservationType
      isAdmin: $isAdmin
    )
  }
`;

export const CANCEL_RESERVATION = gql`
  mutation cancelReservation($reservationId: Float!, $isAdmin: Boolean) {
    cancelReservation(reservationId: $reservationId, isAdmin: $isAdmin) {
      success
      message
    }
  }
`;

export const DUPLICATE_RESERVATION = gql`
  query duplicateReservation($reservationId: Float!, $storeId: Float!) {
    duplicateReservation(reservationId: $reservationId, storeId: $storeId)
  }
`;

export const GET_COUNTRIES_LIST = gql`
  query getCountriesList {
    getContactProperties
  }
`;

export const GET_RESERVATION_PROPERTIES = gql`
  query getReservationProperties($storeId: Float!, $reservationId: Float) {
    getReservationProperties(storeId: $storeId, reservationId: $reservationId)
  }
`;

export const ADD_RESERVATION_CONTACT = gql`
  mutation addReservationContact($reservationContactArgs: ReservationContactArgs!) {
    addReservationContact(reservationContactArgs: $reservationContactArgs) {
      success
      reservationData {
        id
      }
    }
  }
`;

export const EDIT_RESERVATION_CONTACT = gql`
  mutation updateReservationContact($reservationContactArgs: ReservationContactArgs!) {
    updateReservationContact(reservationContactArgs: $reservationContactArgs) {
      success
      reservationData {
        id
      }
    }
  }
`;

export const ADD_RSERVATION_RENTER = gql`
  mutation addReservation($addReservationArgs: AddReservationArgs!) {
    addReservation(addReservationArgs: $addReservationArgs) {
      reservationData {
        id
        startDate
        endDate
        reservationType
      }
    }
  }
`;

export const GET_RENTER_PACKAGES_LIST = gql`
  mutation getRenterPackages($renterPackageArgs: RenterPackageArgs!) {
    getRenterPackages(renterPackageArgs: $renterPackageArgs) {
      success
      data {
        id
        name
        description
        displayOrder
        type
        price
        image
        addon1Id
        addon2Id
        addon3Id
        addon4Id
        addon1Name
        addon2Name
        addon3Name
        addon4Name
        addon1Price
        addon2Price
        addon3Price
        addon4Price
      }
    }
  }
`;

export const ADD_RENTER = gql`
  mutation addRenter($addRenterArgs: AddRenterArgs!) {
    addRenter(addRenterArgs: $addRenterArgs)
  }
`;
export const EDIT_RENTER = gql`
  mutation updateRenter($editRenterArgs: EditRenterArgs!) {
    updateRenter(editRenterArgs: $editRenterArgs)
  }
`;

export const DELETE_RENTER = gql`
  mutation deleteRenter($renterId: String!, $reservationId: Float!) {
    deleteRenter(renterId: $renterId, reservationId: $reservationId) {
      success
      message
    }
  }
`;

export const GET_RETURNS_LIST = gql`
  query getReturnForRenters($reservationId: Float!) {
    getReturnForRenters(reservationId: $reservationId)
  }
`;

export const ADD_CARD = gql`
  mutation addCard($addCardArgs: AddCardArgs!) {
    addCard(addCardArgs: $addCardArgs)
  }
`;

export const DELETE_RESERVATION = gql`
  mutation deleteReservation($storeId: Float!, $reservationId: Float!) {
    deleteReservation(storeId: $storeId, reservationId: $reservationId) {
      success
      message
    }
  }
`;

export const COMPLETE_RESERVATION = gql`
  mutation processingReservation(
    $storeId: Float!
    $reservationId: Float!
    $paymentProfileId: String!
    $card: String!
  ) {
    processingReservation(
      storeId: $storeId
      reservationId: $reservationId
      paymentProfileId: $paymentProfileId
      card: $card
    ) {
      success
      message
    }
  }
`;

export const UPDATE_RENTER_INFORMATION = gql`
  mutation updateRenterInformation(
    $reservationContactArgs: ReservationContactArgs!
    $addReservationArgs: AddReservationArgs!
  ) {
    updateRenterInformation(
      reservationContactArgs: $reservationContactArgs
      addReservationArgs: $addReservationArgs
    ) {
      success
      reservationData {
        id
      }
    }
  }
`;

// Notes Tab
export const NOTE_LIST = gql`
  mutation listNotes($storeId: Float!, $reservationId: Float!) {
    listNotes(storeId: $storeId, reservationId: $reservationId) {
      success
      data {
        id
        note
        createdBy
        createdAt
        employeeName
      }
    }
  }
`;

export const ADD_NOTE = gql`
  mutation addNote($addNotesArgs: AddNotesArgs!) {
    addNote(addNotesArgs: $addNotesArgs) {
      success
      data {
        id
        note
        createdBy
        createdAt
        employeeName
      }
    }
  }
`;
export const UPDATE_NOTE = gql`
  mutation editNote($editNotesArgs: EditNotesArgs!) {
    editNote(editNotesArgs: $editNotesArgs) {
      success
      data {
        id
        note
        createdBy
        createdAt
      }
    }
  }
`;
export const DELETE_NOTE = gql`
  mutation deleteNote($storeId: Float!, $id: String!) {
    deleteNote(storeId: $storeId, id: $id) {
      success
      message
    }
  }
`;

// payment Tab

export const GET_PAYMENT_DETAILS = gql`
  query getReservationPrice($reservationId: Float!, $storeId: Float!, $isAdmin: Boolean) {
    getReservationPrice(reservationId: $reservationId, storeId: $storeId, isAdmin: $isAdmin)
  }
`;

export const APPLY_ADMIN_DISCOUNT = gql`
  mutation applyAdminDiscount(
    $reservationId: Float!
    $isFixedDiscount: Boolean!
    $discount: Float!
  ) {
    applyAdminDiscount(
      reservationId: $reservationId
      isFixedDiscount: $isFixedDiscount
      discount: $discount
    )
  }
`;

export const DELETE_CARD = gql`
  mutation deleteCard($storeId: Float!, $reservationId: Float!, $paymentProfileId: String!) {
    deleteCard(
      storeId: $storeId
      reservationId: $reservationId
      paymentProfileId: $paymentProfileId
    )
  }
`;

export const CHARGE_PAYMENT = gql`
  mutation chargePayment($managePaymentArgs: ManagePaymentArgs!) {
    chargePayment(managePaymentArgs: $managePaymentArgs)
  }
`;

export const REFUND_PAYMENT = gql`
  mutation refundPayment($manageRefundPaymentArgs: ManageRefundPaymentArgs!) {
    refundPayment(manageRefundPaymentArgs: $manageRefundPaymentArgs)
  }
`;

// reservation communication tab
export const GET_COMMUNICATION_LIST = gql`
  query getCommunicationList($storeId: Float!, $reservationId: Float!) {
    listCommunicationLog(reservationId: $reservationId, storeId: $storeId) {
      success
      message
      data {
        id
        message
        subject
        email
        phone
        method
        createdBy
        createdAt
        employeeName
      }
    }
  }
`;

export const ADD_COMMUNICATION_LOG = gql`
  mutation addCommunicationLog($addCommunicationArgs: AddCommunicationArgs!) {
    addCommunicationLog(addCommunicationArgs: $addCommunicationArgs) {
      success
      message
      data {
        id
        message
        subject
        email
        phone
        method
        createdBy
        createdAt
        employeeName
      }
    }
  }
`;

export const GET_WAIVER_PDF = gql`
  mutation generateWaiverPDF($reservationId: Float!, $storeId: Float!) {
    generateWaiverPDF(reservationId: $reservationId, storeId: $storeId)
  }
`;

export const GET_WAIVER_DETAILS = gql`
  query getWaiverDetail($reservationId: Float!) {
    getWaiverDetail(reservationId: $reservationId)
  }
`;

export const RESEND_WAIVER_EMAIL = gql`
  query resendWaiverEmail($reservationId: Float!) {
    resendWaiverEmail(reservationId: $reservationId) {
      success
      message
    }
  }
`;

export const EXPORT_RESERVATION = gql`
  query exportReservation(
    $storeId: Float!
    $searchText: String!
    $equipmentAssigned: String!
    $dateFilter: String!
    $dateRangeFilter: String
  ) {
    exportReservation(
      storeId: $storeId
      searchText: $searchText
      equipmentAssigned: $equipmentAssigned
      dateFilter: $dateFilter
      dateRangeFilter: $dateRangeFilter
    )
  }
`;

export const PRINT_PREVIEW_RESERVATION = gql`
  query printPreview($reservationId: Float!, $storeId: Float!) {
    printPreview(reservationId: $reservationId, storeId: $storeId)
  }
`;

export const GET_RESERVATION_REPORT = gql`
  query getStoreRevenue($dateRangeFilter: String, $timeZone: String) {
    getStoreRevenue(dateRangeFilter: $dateRangeFilter, timeZone: $timeZone) {
      revenueReport {
        amountPaid
        refundAmount
        storeId
        tax
        amountWithoutTax
      }
    }
  }
`;

export const EXPORT_RESERVATION_REPORT = gql`
  query exportReservationReport($dateRangeFilter: String) {
    exportReservationReport(dateRangeFilter: $dateRangeFilter)
  }
`;
