import React, { useContext } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Box, Grid, Button, Dialog, DialogContent, DialogActions } from '@mui/material';
import { CustomInput } from '../../common/customFormControl';
import { styled } from '@mui/material/styles';

import { ModalTitle } from '../../sidenav/modal/ModalHeader';
import SubmitButton from '../../common/button/SubmitButton';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@apollo/client';
import { UPDATE_CUSTOMER_EMAIL } from '../../../graphql/api/customers';
import { CustomerContext } from '../../../pages/customer/CustomerContext';
import { Severity } from '../../../enum/enumList';
import { UpdateCustomerEmailValidator } from './validation/UpdateEmailValidator';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

interface IUpdateModalInterface {
  modalState: { display: boolean; customerDetail: { id: string; name: string; email: string } };
  handleClose: () => void;
}

function UpdateEmailModal({ modalState, handleClose }: IUpdateModalInterface) {
  const { snackbarShowMessage, refetch } = useContext(CustomerContext);

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(UpdateCustomerEmailValidator),
    defaultValues: {
      email: '',
    },
    context: { email: modalState?.customerDetail?.email },
  });

  const [updateCustomerEmail, { loading }] = useMutation(UPDATE_CUSTOMER_EMAIL, {
    onCompleted: (data) => {
      closeModal();
      refetch();
      if (snackbarShowMessage)
        snackbarShowMessage(data?.updateUserEmail?.message, Severity.Success);
    },
    onError: (error) => {
      if (snackbarShowMessage) snackbarShowMessage(error?.message, Severity.Error);
    },
  });

  const closeModal = () => {
    reset();
    handleClose();
  };

  const onUpdateEmail = (data: { email: string }) => {
    const { email } = data;
    updateCustomerEmail({
      variables: {
        customerEmailArgs: {
          id: modalState?.customerDetail?.id,
          email,
        },
      },
    });
  };

  return (
    <div>
      <BootstrapDialog
        onClose={closeModal}
        aria-labelledby='addon-title'
        fullWidth
        maxWidth='xs'
        open={modalState.display}
        className='modal'
      >
        <ModalTitle id='addon-title' className='modal__heading' onClose={closeModal}>
          Update Customer Email
        </ModalTitle>
        <DialogContent dividers>
          <Grid
            container
            component='main'
            className='modal-lcol modal-lcol--border-none'
            spacing={2}
          >
            <Grid item xs={12} md={12} sm={12}>
              <Box component='div'>
                Customer Name{' '}
                <Box component='div' className='label-bg'>
                  <strong>{modalState?.customerDetail?.name}</strong>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={12} sm={12}>
              <Box component='div'>
                Current Email{' '}
                <Box component='div' className='label-bg'>
                  <strong>{modalState?.customerDetail?.email}</strong>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={12} sm={12}>
              <Box component='div' className='modal-col__row'>
                <Controller
                  control={control}
                  name='email'
                  render={({ field, formState: { errors } }) => (
                    <CustomInput
                      field={field}
                      name='email'
                      label='New Email'
                      mainClassName='row__label'
                      placeholder='Enter email'
                      error={errors.email}
                      textInputProps={{ maxLength: 50 }}
                      required
                      fullWidth
                    />
                  )}
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className='modal__footer'>
          <Box sx={{ position: 'relative' }}>
            <SubmitButton isLoading={loading} onClick={handleSubmit(onUpdateEmail)}>
              Save
            </SubmitButton>
          </Box>
          <Button onClick={closeModal} className='cancel-btn' disabled={loading}>
            Cancel
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}

export default UpdateEmailModal;
