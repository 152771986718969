interface HeadCell {
  id: string;
  label: string;
  numeric: boolean;
  isSortAvailable: boolean;
}

export const CustomerTabelCell: HeadCell[] = [
  {
    id: 'fullName',
    numeric: false,
    label: 'Name',
    isSortAvailable: true,
  },
  {
    id: 'email',
    numeric: false,
    label: 'Email',
    isSortAvailable: true,
  },
  {
    id: 'phone',
    numeric: false,
    label: 'Phone',
    isSortAvailable: true,
  },
  {
    id: 'status',
    numeric: false,
    label: 'Status',
    isSortAvailable: true,
  },
  {
    id: 'createdAt',
    numeric: false,
    label: 'Created At',
    isSortAvailable: true,
  },
];

interface Data {
  pickupDate: string;
  endDate: string;
  name: string;
  fullName?: string;
  status?: string;
}

interface CustomerReservationCell {
  id: keyof Data;
  label: string;
  numeric: boolean;
  isSortAvailable: boolean;
}

export const customerReservationHead: CustomerReservationCell[] = [
  {
    id: 'fullName',
    numeric: false,
    label: 'Name',
    isSortAvailable: false,
  },
  {
    id: 'pickupDate',
    numeric: false,
    label: 'Pickup Date',
    isSortAvailable: false,
  },
  {
    id: 'endDate',
    numeric: false,
    label: 'Return Date',
    isSortAvailable: false,
  },
  {
    id: 'status',
    numeric: false,
    label: 'Status',
    isSortAvailable: false,
  },
];
