import { useContext, useMemo } from 'react';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import { useForm, Controller, ControllerRenderProps } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@mui/material';

import * as Images from '../../../Images';

import { RenterModalContext } from '../RenterModalContext';
import { ICategories, Severity } from '../../../../enum/enumList';
import { renterCategoriesValidator } from '../validations/addRenterValidation';
import { CustomInput, CustomSelect } from '../../customFormControl';
import { GET_RENTER_PACKAGES_LIST } from '../../../../graphql/api/reservation';
import { useAuth } from '../../../../hooks/useAuth';
import CustomSubmitButton from '../../button/SubmitButton';
import NoRecordFound from '../../noRecordFound/NoRecordFound';

interface IForm {
  selectedCatergory: string;
  skierType: string;
  skiLengthPreference: string;
  skiLength: boolean;
}
interface IPackageItem {
  item: ICategories;
  field: ControllerRenderProps<IForm, 'selectedCatergory'>;
}
export const PackageDetail = () => {
  const {
    categories,
    handleBack,
    renterDetails,
    reservationDetails,
    setRenterDeatils,
    handleNext,
    snackbarShowMessage,
    skierTypeOptions,
  } = useContext(RenterModalContext);
  const { user } = useAuth();
  const storeId = user?.selectedStore;

  const [getPackagesList, { loading }] = useMutation(GET_RENTER_PACKAGES_LIST);

  const formInitalValue = {
    selectedCatergory: renterDetails?.selectCategory?.packageId?.toString() ?? '',
    skierType: renterDetails?.selectCategory?.skierTypeId ?? '',
    skiLengthPreference: renterDetails?.selectCategory?.skiLengthPreference ?? '',
    skiLength: Boolean(renterDetails?.selectCategory?.isSkiLength) ?? true,
  };
  const { control, handleSubmit, watch } = useForm({
    resolver: yupResolver(renterCategoriesValidator),
    defaultValues: formInitalValue,
  });
  const selectedCatergoryWatcher = watch('selectedCatergory');
  const skiLengthWatcher = watch('skiLength');

  const modifiedSkierTypeOptions = useMemo(
    () => skierTypeOptions.map((item) => ({ label: item.name, value: item.id })),

    [skierTypeOptions],
  );

  const handleFormSubmit = (formData: IForm) => {
    getPackagesList({
      variables: {
        renterPackageArgs: {
          storeId,
          startDate: reservationDetails?.addReservation?.reservationData?.startDate,
          endDate: reservationDetails?.addReservation?.reservationData?.endDate,
          DOB: moment(renterDetails?.dob as any).format('YYYY-MM-DD'),
          type: reservationDetails?.addReservation?.reservationData?.reservationType,
          categoryId: parseInt(formData.selectedCatergory),
        },
      },
      onCompleted(data) {
        setRenterDeatils((prev) => ({
          ...prev,
          packages: data?.getRenterPackages?.data,
          selectCategory: {
            skierTypeId: formData?.skierType ?? null,
            isSkiLength:
              selectedCatergoryWatcher === '1' || selectedCatergoryWatcher === '2'
                ? formData?.skiLength ?? false
                : false,
            skiLengthPreference:
              selectedCatergoryWatcher === '1' || selectedCatergoryWatcher === '2'
                ? formData?.skiLengthPreference ?? ''
                : '',
            packageId: parseInt(formData?.selectedCatergory),
          },
        }));
        handleNext();
      },
      onError(error) {
        if (snackbarShowMessage) snackbarShowMessage(error.message, Severity.Error);
      },
    });
  };

  const PackageItem = ({ item, field }: IPackageItem) => {
    return (
      <Grid item xs={12} sm={6} md={4} lg={4}>
        <Box
          component='label'
          className={
            field.value === String(item?.id)
              ? 'border-block border-block--selected'
              : 'border-block'
          }
          htmlFor={item?.name}
        >
          <Radio
            name={field.name}
            id={item?.name}
            value={item?.id}
            checked={field.value === String(item?.id)}
            onChange={field.onChange}
          />
          <Box className='border-block__img'>
            <Box component='img' src={item?.image ?? Images?.packagesImg[item?.id - 1]}></Box>
          </Box>
          <Box className='border-block__desc'>{item?.name ?? ''}</Box>
        </Box>
      </Grid>
    );
  };

  return (
    <>
      <DialogContent>
        <Grid
          container
          component='main'
          className='modal-lcol modal-lcol--border-none modal-lcol--mt'
          spacing={4}
        >
          {categories?.length ? (
            <Controller
              name='selectedCatergory'
              control={control}
              render={({ field, formState: { errors } }) => (
                <>
                  <RadioGroup
                    row
                    aria-labelledby='radio-for-packages-label'
                    className='radio-block'
                  >
                    <Grid container component='div' spacing={3}>
                      {categories.map((item) => (
                        <PackageItem key={item?.name} item={item} field={field} />
                      ))}
                    </Grid>
                  </RadioGroup>
                  {errors && errors.selectedCatergory && (
                    <Typography color='red'>{errors.selectedCatergory.message}</Typography>
                  )}
                </>
              )}
            />
          ) : (
            <NoRecordFound text='No category found' />
          )}

          {selectedCatergoryWatcher === '1' || selectedCatergoryWatcher === '2' ? (
            <Grid container component='div' spacing={3} sx={{ mt: 2 }}>
              {selectedCatergoryWatcher === '1' && (
                <Grid item xs={12} md={6} lg={6} className='field-wrap'>
                  <Controller
                    control={control}
                    name='skierType'
                    render={({ field, formState }) => (
                      <CustomSelect
                        id='skier-type'
                        label=' Skier Type'
                        mainClassName='label label--width'
                        placeholder='-- Select --'
                        menuItems={modifiedSkierTypeOptions}
                        field={field}
                        error={formState.errors.skierType}
                        fullWidth
                        required
                        showTooltip
                        tooltipContent={
                          <>
                            <Box component={'div'} className='tooltip__list'>
                              Beginner: Least aggressive
                            </Box>
                            <Box component={'div'} className='tooltip__list'>
                              Intermediate: Moderately aggressive
                            </Box>
                            <Box component={'div'} className='tooltip__list'>
                              Expert: Most aggressive
                            </Box>
                          </>
                        }
                      />
                    )}
                  />
                </Grid>
              )}
              <Grid item xs={12} md={6} lg={6}>
                <Box component='div' className='block-shadow'>
                  <Box component='div' className='block-shadow__heading'>
                    Length Preference ?
                  </Box>
                  <Box component='div' className='block-shadow__content'>
                    <Controller
                      name='skiLength'
                      control={control}
                      render={({ field, formState: { errors } }) => (
                        <>
                          <RadioGroup
                            row
                            aria-labelledby='ski-length-label'
                            name='skiLength'
                            onChange={(_, value) => {
                              if (value === 'true') return field.onChange(true);
                              return field.onChange(false);
                            }}
                            value={field.value}
                          >
                            <FormControlLabel
                              value={true}
                              control={<Radio />}
                              label='Yes'
                              checked={field.value}
                            />
                            <FormControlLabel
                              value={false}
                              control={<Radio />}
                              label='No'
                              checked={!field.value}
                            />
                          </RadioGroup>
                          {errors && errors.skiLength && (
                            <Typography color='red'>{errors.skiLength.message}</Typography>
                          )}
                        </>
                      )}
                    />

                    {skiLengthWatcher ? (
                      <Controller
                        control={control}
                        name='skiLengthPreference'
                        render={({ field, formState }) => (
                          <CustomInput
                            mainClassName='label'
                            name='skiLengthPreference'
                            placeholder='Length Preference/Range'
                            type='text'
                            field={field}
                            error={formState.errors.skiLengthPreference}
                            textInputProps={{
                              maxLength: 10,
                            }}
                            fullWidth
                          />
                        )}
                      />
                    ) : null}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      </DialogContent>

      <DialogActions className='modal__footer'>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Button disabled={loading} onClick={handleBack} sx={{ mr: 2 }} className='back-btn'>
            Previous
          </Button>

          <CustomSubmitButton
            onClick={handleSubmit(handleFormSubmit)}
            isLoading={loading}
            disabled={!categories?.length}
          >
            Next
          </CustomSubmitButton>
        </Box>
      </DialogActions>
    </>
  );
};
