import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Grid, TextareaAutosize, Dialog, DialogContent, Typography } from '@mui/material';

import { ModalTitle } from '../../sidenav/modal/ModalHeader';

import { IAddNoteModal } from '../../../models/reservation';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

interface IFeedbackModalProps {
  modalOpen: boolean;
  review: string;
  handleClose: () => void;
}

const FeedbackReviewModal = ({ modalOpen, handleClose, review }: IFeedbackModalProps) => {
  const handleModalClose = () => {
    handleClose();
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleModalClose}
        aria-labelledby='add-card-title'
        fullWidth
        maxWidth='xs'
        open={modalOpen}
        className='modal modal--card'
      >
        <ModalTitle id='add-card-title' className='modal__heading' onClose={handleModalClose}>
          Review
        </ModalTitle>
        <DialogContent dividers>
          <Grid
            container
            className='modal-lcol modal-lcol--border-none'
            component='main'
            spacing={3}
          >
            <Grid item xs={12} md={12} sm={12}>
              <Box component='p' className='modal-col__row'>
                {review}
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};

export default FeedbackReviewModal;
