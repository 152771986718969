import { lazy } from 'react';
import {
  HomeOutlined as HomeOutlinedIcon,
  SettingsOutlined as SettingsOutlinedIcon,
  EventAvailableOutlined as EventAvailableOutlinedIcon,
  ExtensionOutlined as ExtensionOutlinedIcon,
  Inventory2Outlined as Inventory2OutlinedIcon,
  AccountBoxOutlined as AccountBoxOutlinedIcon,
  CollectionsBookmarkOutlined as CollectionsBookmarkOutlinedIcon,
  GroupOutlined as GroupOutlinedIcon,
  KeyboardReturnOutlined as KeyboardReturnOutlinedIcon,
  Assessment as AssessmentIcon,
  AddLink as AddLinkIcon,
  Star as StarIcon,
} from '@mui/icons-material';
import { rolesEnum } from '../enum/enumList';

export const guestRoutes = [
  {
    exact: true,
    path: '/',
    name: 'Login',
    component: lazy(() => import('../pages/login/Login')),
  },
  // {
  //   exact: true,
  //   path: '/login',
  //   name: 'Login',
  //   component: lazy(() => import('../pages/login/Login')),
  // },
  {
    exact: true,
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: lazy(() => import('../pages/forgot-password/ForgotPassword')),
  },
  {
    exact: true,
    path: '/reset-password',
    name: 'ResetPassword',
    component: lazy(() => import('../pages/reset-password/ResetPassword')),
  },
  {
    exact: true,
    path: '/new-password',
    name: 'NewPassword',
    component: lazy(() => import('../pages/reset-password/ResetPassword')),
  },

  {
    redirectRoute: true,
    name: 'Login',
    path: '/',
  },
];

export const userRoutes = [
  // {
  //   exact: true,
  //   path: '/home',
  //   name: 'Home',
  //   isSideBar: true,
  //   icon: <HomeOutlinedIcon />,
  //   component: lazy(() => import('../pages/home/Home')),
  // },
  {
    exact: true,
    path: '/',
    name: 'Home',
    isSideBar: true,
    icon: <HomeOutlinedIcon />,
    component: lazy(() => import('../pages/home/Home')),
    role: [rolesEnum.superadmin, rolesEnum.admin, rolesEnum.employee],
  },
  {
    exact: true,
    path: '/reservations',
    name: 'Reservations',
    isSideBar: true,
    icon: <EventAvailableOutlinedIcon />,
    component: lazy(() => import('../pages/reservation/reservationListing/ReservationListing')),
    role: [rolesEnum.superadmin, rolesEnum.admin, rolesEnum.employee],
  },
  {
    exact: true,
    path: '/reservation/contact-info',
    name: 'ReservationContactInfo',
    isSideBar: false,
    component: lazy(() => import('../pages/reservation/addReservation/AddReservationWrapper')),
    role: [rolesEnum.superadmin, rolesEnum.admin, rolesEnum.employee],
  },
  {
    exact: true,
    path: '/reservation/add-reservation',
    name: 'AddEditReservation',
    isSideBar: false,
    component: lazy(() => import('../pages/reservation/addReservation/AddReservationWrapper')),
    role: [rolesEnum.superadmin, rolesEnum.admin, rolesEnum.employee],
  },
  {
    exact: true,
    path: '/reservation/payment',
    name: 'ReservationContactDetail',
    isSideBar: false,
    component: lazy(() => import('../pages/reservation/addReservation/AddReservationWrapper')),
    role: [rolesEnum.superadmin, rolesEnum.admin, rolesEnum.employee],
  },
  {
    exact: true,
    path: '/reservation/reservation-detail',
    name: 'ReservationDetail',
    isSideBar: false,
    component: lazy(
      () => import('../pages/reservation/reservationDetail/ReservationDetailWrapper'),
    ),
    role: [rolesEnum.superadmin, rolesEnum.admin, rolesEnum.employee],
  },
  {
    exact: true,
    path: '/reservation/rental-detail',
    name: 'EditRentalInformation',
    isSideBar: false,
    component: lazy(
      () => import('../pages/reservation/reservationDetail/ReservationDetailWrapper'),
    ),
    role: [rolesEnum.superadmin, rolesEnum.admin, rolesEnum.employee],
  },
  {
    exact: true,
    path: '/reservation/tech-listing',
    name: 'TechStationListing',
    isSideBar: false,
    component: lazy(() => import('../pages/tech-station/TechStationWrapper')),
    role: [rolesEnum.superadmin, rolesEnum.admin, rolesEnum.employee],
  },
  {
    exact: true,
    path: '/reservation/assign-equipment',
    name: 'AssignEquipment',
    isSideBar: false,
    component: lazy(() => import('../pages/tech-station/TechStationWrapper')),
    role: [rolesEnum.superadmin, rolesEnum.admin, rolesEnum.employee],
  },
  {
    exact: true,
    path: '/return',
    name: 'Return',
    isSideBar: true,
    icon: <KeyboardReturnOutlinedIcon />,
    component: lazy(() => import('../pages/return/ReturnListing')),
    role: [rolesEnum.superadmin, rolesEnum.admin, rolesEnum.employee],
  },
  {
    exact: true,
    path: '/:reservationId/return-details',
    name: 'ReturnDetails',
    isSideBar: false,
    component: lazy(() => import('../pages/return/ReturnsDetails')),
    role: [rolesEnum.superadmin, rolesEnum.admin, rolesEnum.employee],
  },
  {
    exact: true,
    path: '/packages',
    name: 'Packages',
    isSideBar: true,
    icon: <CollectionsBookmarkOutlinedIcon />,
    component: lazy(() => import('../pages/package/PackagesListing')),
    role: [rolesEnum.superadmin, rolesEnum.admin, rolesEnum.employee],
  },
  {
    exact: true,
    path: '/packages/add-package',
    name: 'AddEditPackage',
    isSideBar: false,
    component: lazy(() => import('../pages/package/AddEditPackage')),
    role: [rolesEnum.superadmin, rolesEnum.admin, rolesEnum.employee],
  },
  {
    exact: true,
    path: '/addons',
    name: 'Addons',
    isSideBar: true,
    icon: <ExtensionOutlinedIcon />,
    component: lazy(() => import('../pages/addon/AddonListing')),
    role: [rolesEnum.superadmin, rolesEnum.admin, rolesEnum.employee],
  },
  {
    exact: true,
    path: '/inventories',
    name: 'Inventory',
    isSideBar: true,
    icon: <Inventory2OutlinedIcon />,
    component: lazy(() => import('../pages/inventory/InventoryListing')),
    role: [rolesEnum.superadmin, rolesEnum.admin, rolesEnum.employee],
  },
  {
    exact: true,
    path: '/inventories/add-inventory',
    name: 'AddEditInventoryItem',
    isSideBar: false,
    component: lazy(() => import('../pages/inventory/AddEditInventoryItem')),
    role: [rolesEnum.superadmin, rolesEnum.admin, rolesEnum.employee],
  },
  {
    exact: true,
    path: '/inventories/edit-inventory',
    name: 'AddEditInventoryItem',
    isSideBar: false,
    component: lazy(() => import('../pages/inventory/AddEditInventoryItem')),
    role: [rolesEnum.superadmin, rolesEnum.admin, rolesEnum.employee],
  },
  {
    exact: true,
    path: '/employees',
    name: 'Employees',
    isSideBar: true,
    icon: <AccountBoxOutlinedIcon />,
    component: lazy(() => import('../pages/employee/EmployeeWrapper')),
    role: [rolesEnum.superadmin, rolesEnum.admin],
  },
  {
    exact: true,
    path: '/customers',
    name: 'Customers',
    isSideBar: true,
    icon: <GroupOutlinedIcon />,
    component: lazy(() => import('../pages/customer/CustomerWrapper')),
    role: [rolesEnum.superadmin, rolesEnum.admin, rolesEnum.employee],
  },
  {
    exact: true,
    path: '/customer-detail/:customerId',
    name: 'CustomerDetail',
    isSideBar: false,
    component: lazy(() => import('../pages/customer/CustomerDetail')),
    role: [rolesEnum.superadmin, rolesEnum.admin, rolesEnum.employee],
  },
  {
    exact: true,
    path: '/reports',
    name: 'Reports',
    isSideBar: true,
    icon: <AssessmentIcon />,
    component: lazy(() => import('../pages/inventory/InventoryReport/InventoryReport')),
    role: [rolesEnum.superadmin, rolesEnum.admin],
  },
  {
    exact: true,
    path: '/inventory-reports',
    name: 'Inventory Report',
    isSideBar: false,
    icon: <AssessmentIcon />,
    component: lazy(() => import('../pages/inventory/InventoryReport/InventoryReport')),
    role: [rolesEnum.superadmin, rolesEnum.admin, rolesEnum.employee],
  },
  {
    exact: true,
    path: '/reservation-revenue',
    name: 'Reservation Report',
    isSideBar: false,
    icon: <AssessmentIcon />,
    component: lazy(() => import('../pages/reservation/reservationRevenue/ReservationRevenue')),
    role: [rolesEnum.superadmin, rolesEnum.admin, rolesEnum.employee],
  },
  {
    exact: true,
    path: '/settings',
    name: 'Settings',
    isSideBar: true,
    icon: <SettingsOutlinedIcon />,
    component: lazy(() => import('../pages/settings/ReservationSettings')),
    role: [rolesEnum.superadmin, rolesEnum.admin],
  },
  {
    exact: true,
    path: '/admin-settings',
    name: 'AdminSettings',
    isSideBar: false,
    icon: <SettingsOutlinedIcon />,
    component: lazy(() => import('../pages/settings/ReservationSettings')),
    role: [rolesEnum.superadmin, rolesEnum.admin],
  },
  {
    exact: true,
    path: '/cms-settings',
    name: 'CMSSettings',
    isSideBar: false,
    icon: <SettingsOutlinedIcon />,
    component: lazy(() => import('../pages/settings/CmsSettings')),
    role: [rolesEnum.superadmin, rolesEnum.admin],
  },
  {
    exact: true,
    path: '/profile',
    name: 'ProfilePage',
    isSideBar: false,
    component: lazy(() => import('../pages/employee/Profile')),
    role: [rolesEnum.superadmin, rolesEnum.admin, rolesEnum.employee],
  },
  {
    exact: true,
    path: '/employee/add-employee',
    name: 'AddEditEmployee',
    isSideBar: false,
    component: lazy(() => import('../pages/employee/EmployeeWrapper')),
    role: [rolesEnum.superadmin, rolesEnum.admin],
  },
  {
    exact: true,
    path: '/employee/edit-employee',
    name: 'EditEmployee',
    isSideBar: false,
    component: lazy(() => import('../pages/employee/EmployeeWrapper')),
    role: [rolesEnum.superadmin, rolesEnum.admin],
  },
  {
    exact: true,
    path: '/inventories/view-history/:inventoryId',
    name: 'InventoryHistory',
    isSideBar: false,
    component: lazy(() => import('../pages/inventory/InventoryHistory')),
    role: [rolesEnum.superadmin, rolesEnum.admin, rolesEnum.employee],
  },
  {
    exact: true,
    path: '/unlock',
    name: 'UnlockScreen',
    isSideBar: false,
    component: lazy(() => import('../pages/unlock/Unlock')),
    role: [rolesEnum.superadmin, rolesEnum.admin, rolesEnum.employee],
  },
  {
    exact: true,
    path: '/affiliates',
    name: 'Affiliates',
    isSideBar: true,
    icon: <AddLinkIcon />,
    component: lazy(() => import('../pages/affiliates/AffiliatesListing')),
    role: [rolesEnum.superadmin, rolesEnum.admin],
  },
  {
    exact: true,
    path: '/affiliates/add-affiliate',
    name: 'AddAffiliates',
    isSideBar: false,
    component: lazy(() => import('../pages/affiliates/AddEditAffiliates')),
    role: [rolesEnum.superadmin, rolesEnum.admin],
  },
  {
    exact: true,
    path: '/affiliates/edit-affiliate',
    name: 'EditAffiliates',
    isSideBar: false,
    component: lazy(() => import('../pages/affiliates/AddEditAffiliates')),
    role: [rolesEnum.superadmin, rolesEnum.admin],
  },
  {
    exact: true,
    path: '/reservation-feedback',
    name: 'Feedback',
    isSideBar: true,
    icon: <StarIcon />,
    component: lazy(() => import('../pages/reservation-feedback/ReservationFeedback')),
    role: [rolesEnum.superadmin, rolesEnum.admin, rolesEnum.employee],
  },
  {
    redirectRoute: true,
    name: 'ErorrPage',
    path: '/',
    role: [rolesEnum.superadmin, rolesEnum.admin, rolesEnum.employee],
  },
];
